import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { AuthContext } from './auth-context';
import { isValidToken, setSession, setProfileData, refreshToken } from './utils'; // Ensure refreshToken is imported
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import axios from 'axios';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return {
        loading: false,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'REGISTER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      let accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && !isValidToken(accessToken)) {
        accessToken = await refreshToken();
      }

      if (accessToken) {
        setSession(accessToken);
        const response = await axiosInstance.get(endpoints.auth.me);
        const { user } = response.data;

        localStorage.setItem('isFirstLogin', user.is_first_login);
        localStorage.setItem('userType', user.user_type);
        localStorage.setItem('isUserVerified', user.is_verified);

        if (user?.influencerId !== null) {
          localStorage.setItem('influencerId', user?.influencerId);
        }
        if (response.data.success) {
          setProfileData(
            user.username,
            user.email,
            user.photo_url,
            user.signup_source,
            user.agency?.agency_name,
            user.agency?.holding_company_name,
            user.brand_name,
            user.major_type,
            user.product_type,
            user.brand_interest,
            user.social_profiles?.facebook[0] || '',
            user.social_profiles?.instagram[0] || '',
            user.social_profiles?.twitter[0] || '',
            user.social_profiles?.youtube[0] || '',
            user.social_profiles?.tiktok[0] || '',
            user.gender,
            user.pronoun,
            user.region,
            user.age
          );
        } else {
          localStorage.clear();
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      localStorage.clear();
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    try {
      const data = { email, password };
      const response = await axiosInstance.post(endpoints.auth.login, data);
      const { access, refresh, user } = response.data;

      localStorage.setItem('accessTokenResponse', access);
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshTokenResponse', refresh);
      localStorage.setItem('refreshToken', refresh);

      if (!user.is_verified) {
        localStorage.setItem('sessionUserType', user.user_type);
        window.location.href = paths.auth.jwt.register;
      } else {
        router.push(paths.dashboard.uploadInfluencer);

        setSession(access);
        setProfileData(user.username, user.email, user.photo_url, user?.signup_source, user?.agency?.agency_name);

        dispatch({
          type: 'LOGIN',
          payload: { user },
        });
      }
    } catch (e) {
      console.log('e');
    }
  }, [router]);

  // REGISTER
  const register = useCallback(async (email, name, password) => {
    const data = { email, name, password };
    const response = await axiosInstance.post(endpoints.auth.register, data);
    const { access, refresh, user } = response.data;

    localStorage.setItem(STORAGE_KEY, access);
    localStorage.setItem('refreshTokenResponse', refresh);
    setSession(access);

    dispatch({
      type: 'REGISTER',
      payload: { user },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  }, []);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
