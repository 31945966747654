import * as Yup from "yup";
import Modal from '@mui/material/Modal';
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Icon } from "@iconify/react";
import { useSnackbar } from 'src/components/snackbar';
import "./bulk_upload.css";

// routes
import { paths } from "src/routes/paths";
import { RouterLink } from "src/routes/components";
import { useSearchParams, useRouter } from "src/routes/hooks";
// config
import { HOST_API, PATH_AFTER_LOGIN } from "src/config-global";
// hooks
import { useBoolean } from "src/hooks/use-boolean";
// auth
import { useAuthContext } from "src/auth/hooks";
// components
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import { register } from "numeral";
import PropTypes from "prop-types";
import "./bulk_upload.css";

const style = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: " 500px",
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // maxHeight: '685px',
  // borderRadius: "15px"
};

export default function AddInfluencerModal(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { login } = useAuthContext();

  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState("");

  const searchParams = useSearchParams();

  const [urlMessage, setUrlMessage] = useState(false)

  const [userType, setUserTye] = useState(
    localStorage.getItem("sessionUserType")
  );

  const [region, setregion] = useState("");
  const [regionError, setRegionError] = useState("")
  const [gender, setgender] = useState("");
  const [genderError, setGenderError] = useState("")

  const [profileUrlData, setProfileUrlData] = useState([])

  const [formErrors, setFormErrors] = useState({});

  const [socialLinkButtondisable, setsocialLinkButtondisable] = useState(true);

  const handleInfluencerregionChange = (event) => {
    setregion(
      event.target.value
    );
    setRegionError('')
  };
  const handleInfluencergenderTypeChange = (event) => {
    setgender(
      event.target.value
    );
    setGenderError('')
  };

  const defaultValues = {
    // facebook: 'qwerty.com',
    // instagram: 'qwerty.com',
    // youtube: 'qwerty.com',
    // twitter: 'qwerty.com',
    // tiktok: 'qwerty'
  };

  const socialProfiles = {
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    tiktok: '',
  }

  const ProfileUrlSchema = Yup.object().shape({

    email: Yup.string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email must be a valid email address'
      )
      .required('Email is required'),
    name: Yup.string().required('Name is required'),
    age: Yup.number()
      .nullable()
      .notRequired()
      .min(0)
      .max(100, 'Age must be less than 100')
      .moreThan(-1, 'Negative values not accepted'),

    facebook: Yup.string()
      .notRequired()
      .test('valid-facebook', 'Invalid Facebook username', function (value) {
        if (!value) {
          return true;
        }
        const facebookUsernamePattern = /^[a-zA-Z0-9_\-\.]+$/;
        return facebookUsernamePattern.test(value);
      }),
    twitter: Yup.string()
      .notRequired()
      .test('valid-twitter', 'Invalid Twitter username', function (value) {
        if (!value) {
          return true;
        }
        const twitterUsernamePattern = /^[a-zA-Z0-9_]+$/;
        return twitterUsernamePattern.test(value);
      }),
    instagram: Yup.string()
      .notRequired()
      .test('valid-instagram', 'Invalid Instagram username', function (value) {
        if (!value) {
          return true;
        }
        const instagramUsernamePattern = /^[a-zA-Z0-9_\-\.]+$/;
        return instagramUsernamePattern.test(value);
      }),
    youtube: Yup.string()
      .notRequired()
      .test('valid-youtube', 'Invalid YouTube username.', function (value) {
        if (!value) {
          return true;
        }
        const youtubeUsernamePattern = /^[a-zA-Z0-9_]+$/;
        return youtubeUsernamePattern.test(value);
      }),
    tiktok: Yup.string()
      .notRequired()
      .test('valid-tiktok', 'Invalid TikTok username.', function (value) {
        if (!value) {
          return true;
        }
        const tiktokUsernamePattern = /^[a-zA-Z0-9_\-\.]+$/;
        return tiktokUsernamePattern.test(value);
      }),
  });

  const methods = useForm({
    resolver: yupResolver(ProfileUrlSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

  const onSubmit = handleSubmit(async (profileData) => {
    profileData.region = region;
    profileData.gender = gender;

    if (region === "" && gender === "") {
      setRegionError('Region is required')
      setGenderError('Gender is required')
      return
    }
    else if (region === "") {
      setRegionError('Region is required')
      return
    }
    else if (gender === "") {
      setGenderError('Gender is required')
      return
    }

    if (!profileUrlData) {
      setUrlMessage(true)
      setErrorMsg(false)
      return;
    }

    const socialData = {
      email: profileData.email,
      name: profileData.name,
      age: profileData.age,
      region: profileData.region,
      gender: profileData.gender,
      agency: profileData.agency,
      social_profiles: profileUrlData
    };

    const apiUrl = `${HOST_API}/api/influencer/addinfluencer`;

    if (Object.values(profileUrlData).every(value => !value)) {
      setUrlMessage(true);
    } else {
      setUrlMessage(false)

      const header = {
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(socialData),
      };

      try {
        fetch(apiUrl, {
          method: "POST",
          headers: header.headers,
          body: header.body,
        })
          .then((response) => response.json())
          .then((response) => {
            if (!response.success) {
              enqueueSnackbar(response.message, { variant: 'error' });
            }
            else {
              enqueueSnackbar("Creator created successfully!")
              props.onClose();
              props.setCreateInfluencerManually(true);
            }
          })
          .catch((error) => {
            enqueueSnackbar("Error occurred during API call", { variant: 'error' });
            console.error("API Error:", error);
          });
      }
      catch (error) {
        enqueueSnackbar("Error occurred", { variant: 'error' });
        console.error("API Error:", error);
      }
    }
  });


  const addInfluencer = () => {
    props.onClose();
  }
  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, textAlign: "center" }}>
      <div className="add_influencer_header">Add Creators Manually</div>
    </Stack>
  );

  const handleKeyPress = (event) => {
    if (event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const updatedProfile = {
      profile_url: value,
      platform: name

    };
    setProfileUrlData(prevProfileData => {
      const index = prevProfileData.findIndex(item => item.platform === name);
      if (index !== -1) {
        if (value !== '') {
          return prevProfileData.map(item => (item.platform === name ? updatedProfile : item));
        } else {
          return prevProfileData.filter(item => item.platform !== name);
        }
      } else if (value !== '') {
        return [...prevProfileData, updatedProfile];
      }
      return prevProfileData;
    });

    if (value) {
      setUrlMessage(false);
    }
    setsocialLinkButtondisable(false)

    try {
      await ProfileUrlSchema.validateAt(name, { ...profileUrlData, [name]: value });
      setFormErrors(prevErrors => ({ ...prevErrors, [name]: null }));
    } catch (err) {
      setFormErrors(prevErrors => ({ ...prevErrors, [name]: err?.errors?.[0] }));
      setsocialLinkButtondisable(true)
    }
  }

  useEffect(() => {
    const hasErrors = Object.values(formErrors).some(error => error !== null);
    setsocialLinkButtondisable(hasErrors)
  }, [formErrors]);

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      <div className="parent_box">
        <span className="textLabel">Email</span>
        <RHFTextField name="email" placeholder="Email" />
      </div>
      <div className="parent_box">
        <span className="textLabel">Name</span>
        <RHFTextField name="name" placeholder="Name" />
      </div>
      <div className="add-influencer-wrapper" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="parent_box" style={{ flex: '1', width: '120px' }}>
          <span className="textLabel">Age</span>
          <RHFTextField
            type="number"
            name="age"
            placeholder="Years"
            onKeyPress={handleKeyPress}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', marginLeft: '20px', marginRight: '20px', width: '120px' }}>
          <div className="parent_box">
            <span className="textLabel">Region</span>

            <Select
              style={{ backgroundColor: '#F6F6F6' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="add-influencer-menu"
              value={region}
              // label="Age"
              onChange={handleInfluencerregionChange}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="united_states">US</MenuItem>
              <MenuItem value="canada">Canada</MenuItem>
              <MenuItem value="uk">UK</MenuItem>
              <MenuItem value="europe">Europe</MenuItem>
              <MenuItem value="middle_east">Middle East</MenuItem>
              <MenuItem value="india">India</MenuItem>
              <MenuItem value="asia">Asia</MenuItem>
              <MenuItem value="australia">Australia</MenuItem>
            </Select>
          </div>
          {regionError !== '' && (
            <div
              className="customError"
              style={{ color: '#FF5630', fontSize: '12px' }}
            >
              {regionError}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', width: '120px' }}>
          <div className="parent_box">
            <span className="textLabel">Gender</span>
            <Select
              style={{ backgroundColor: '#F6F6F6' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="add-influencer-menu"
              value={gender}
              // label="Age"
              onChange={handleInfluencergenderTypeChange}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="non_binary">Non-binary</MenuItem>
            </Select>
          </div>
          {genderError !== '' && (
            <div
              className="customError"
              style={{ color: '#FF5630', fontSize: '12px' }}
            >
              {genderError}
            </div>
          )}
        </div>
      </div>


      <Stack spacing={2} sx={{ textAlign: 'center' }}>
        <div className="social-networks">Add Social Networks</div>
      </Stack>

      {Object.keys(socialProfiles).map((link) => (
        <div key={link}>
          <RHFTextField
            name={link}
            placeholder={`profile name`}
            autoComplete={'off'}
            onChange={handleChange}
            style={{ backgroundColor: '#F6F6F6', borderRadius: '8px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    width={24}
                    icon={
                      (link === 'facebook' && 'logos:facebook') ||
                      (link === 'instagram' && 'skill-icons:instagram') ||
                      (link === 'twitter' && 'simple-icons:x') ||
                      (link === 'youtube' && 'ant-design:youtube-filled') ||
                      (link === 'tiktok' && 'ic:round-tiktok') ||
                      ''
                    }
                    color={
                      (link === 'facebook' && '#1877F2') ||
                      (link === 'instagram' && '#DF3E30') ||
                      (link === 'twitter' && '#000000') ||
                      (link === 'youtube' && '#FF0000') ||
                      (link === 'tiktok' && '#000000') ||
                      ''
                    }
                  />
                  <span style={{ fontSize: '14px', marginLeft: '5px' }}>@</span>
                </InputAdornment>
              ),
            }}
          />
          {formErrors[link] && (
            <Typography variant="caption" color="error"
              style={{ marginLeft: '15px', fontSize: '12px', color: '#FD6341' }}
            >
              {formErrors[link]}
            </Typography>
          )}
        </div>
      ))}

      {urlMessage && (
        <Stack
          component="span"
          direction="row"
          alignItems="center"
          style={{ marginLeft: '5px', fontSize: '15px', color: '#FD6341' }}
        >
          <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Enter at least one social
          network profile.
        </Stack>
      )}

      {/* {renderTerms} */}

      <LoadingButton
        style={!socialLinkButtondisable ? { backgroundColor: '#458DE1' } : {}}
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={socialLinkButtondisable}
      >
        Create
      </LoadingButton>
      {/* <LoadingButton
        style={{ backgroundColor: 'grey' }}
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        // loading={isSubmitting}
        onClick={() => addInfluencer()}
      >
        Cancel
      </LoadingButton> */}
    </Stack>
  );
  return (
    // <Modal
    //   open={props.open}
    //   onClose={props.onClose}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Box sx={style}>
      {userType !== "brand" && (
        <FormProvider methods={methods} onSubmit={onSubmit} classname="upload_influencer_modal">
          {renderHead}
          {renderForm}
        </FormProvider>
      )}
    </Box>
    // </Modal>
  )
}
